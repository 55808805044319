body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-clttge-MuiAutocomplete-root .MuiInput-root .MuiInput-input {
  padding: 12px 24px !important;
}

.css-4jnixx-MuiStack-root {
  padding: 0 !important;
}

.scroll-button {
  background-color: #735EAB;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-button.left {
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  z-index: 10;
}

.scroll-button.right {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  z-index: 10;
}

.scroll-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  scroll-behavior: smooth;
}

@media screen and (max-width: 768px) {
  .scroll-button.left {
    left: 0;
  }

  .scroll-button.right {
    right: 0;
  }
}

.scroll-hidden {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}
